.SI-container{
    background-color: rgb(255, 255, 255);
    width: 100vw;
    height: 85vh;
    position: relative;
    margin: 50px 0px ;
}

.SI-container-img {
    height: 100%;
    width: 80%;
    object-fit: cover;
    float: left;
}

.SI-data h1{
    font-size: 2rem;
    font-weight: 400;
    color: var(--p-red);
}
.SI-data p {
    font-size: 1rem;
    font-weight: 400;
    color: rgb(49, 49, 49);
}
.SI-data a {
    font-weight: 500;
    font-size: .9rem;
    text-decoration: none;
    color: rgb(16, 16, 16);
}
/* 
.SI-image{
    width: 100%;
    height: auto;
} */
.SI-data{
    position: absolute;
    top: 25%;
    left:60%;
    background-color: rgb(255, 255, 255);
    padding: 40px;
    width: 35%;
    z-index: 1;
}

.SI-container-right{
    background-color: rgb(255, 255, 255);
    width: 100vw;
    height: 85vh;
    position: relative;
    margin: 50px 0px ;
    float: right;
}

.SI-data-right{
    position: absolute;
    top: 25%;
    right:60%;
    background-color: rgb(255, 255, 255);
    padding: 40px;
    width: 35%;
    z-index: 1;
}
.SI-container-img-right{
    height: 100%;
    width: 80%;
    object-fit: cover;
    float: right;
}
.SI-data-right h1{
    font-size: 2rem;
    font-weight: 400;
    color: var(--p-red);
}
.SI-data-right p {
    font-size: 1rem;
    font-weight: 400;
    color: rgb(49, 49, 49);
}
.SI-data-right a {
    font-weight: 500;
    font-size: .9rem;
    text-decoration: none;
    color: rgb(16, 16, 16);
}

@media all and (width <=430px){
    .SI-data{
        display: block;
        position: static;
        width: 90%;
        padding: 10px;
        padding-bottom: 20px; 
    }
    .SI-container{
        width: 100%;
        margin: 0px 0px ;

    }
    .SI-container-img {
        width: 100%;
        height: 50vh;
    }
    .SI-data h1{
        font-size: 1.6rem;
    }
    .SI-data-right{
        display: block;
        position: static;
        width: 90%;
        padding: 10px;
        padding-bottom: 20px; 
    }
    .SI-container-img-right{
        width: 100%;
        height: 50vh;
    }
    .SI-data-right h1{
        font-size: 2rem;
        font-weight: 400;
        color: var(--p-red);
    }
    .SI-data-right p {
        font-size: 1rem;
        font-weight: 400;
        color: rgb(49, 49, 49);
    }
    .SI-data-right a {
        font-weight: 500;
        font-size: .9rem;
        text-decoration: none;
        color: rgb(16, 16, 16);
    }
}