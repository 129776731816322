 .t-card-container{
    padding: 50px 0px;
}
.t-image {
    width: 90%;
    
}
.t-card-col1 {
    align-self: center;
}

.t-card-col2 h1{
    font-weight: 400;
    font-size: 2.2rem;
    margin-top: 0px;
}
.t-card-col2 p {
    padding: 0px 0px ;
    margin:8px 0px;
}
.t-card-data >h1{

    font-size: 2.8rem;
    margin-bottom: -6px;
    font-weight: 400;

}
.t-card-person{
    margin: 5px 0px;
}
.t-card-person img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.t-card-person{
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: start;
}

.t-card-person-data  {
    font-weight: 400;

}
.t-card-person-data h4  {
    font-weight: 500;
    font-size: 1rem;
    margin: 0px;

}
.t-card-person-data p {
    font-size: 1rem;
    color: rgb(156, 156, 156);
    margin: 4px 0px ;
}
.test-data h1{
    font-size: 3.2rem;
    font-weight: 00 ;
    padding: 0px ;
    margin: 24px 0px;
}

.test-data hr{
    border: 3px solid var(--p-red);
    width: 40%;
    margin-left: 10%;
}

.test-slider .nextArrow {
    color: white !important;
    top: 77%;
    position: absolute;
    right: 40%;
    z-index: 1;
    height: 30px;
    width: 30px;
    cursor: pointer;
    background-color: var(--p-red);
    padding: 10px;
    border: 1px solid var(--p-red);
    border-radius: 50%;
}

.test-slider .prevArrow {
    color: white !important;
    /* left: 50% !important;
      right: 50% !important; */
      background-color: var(--p-red);
      padding: 10px;
      border: 1px solid var(--p-red);
      border-radius: 50%;
    top: 77%;
    height: 30px;
    width: 30px;
    position: absolute;
    z-index: 1;
    left: 50%;
    
    cursor: pointer;
}
 
.test-slider .prevArrow:hover , .nextArrow:hover{
    background-color: white;
    color: var(--p-red) !important;

}

.test-data h1{
    font-weight: 400;
}

@media all and (width <=430px) {
    .test-slider .prevArrow{
        left: 5%;
        top: 92%;
    }
    .test-slider .nextArrow{
        right:5%;
        top: 92%;
    }
    .t-card-col2 h1 {
        font-weight: 400;
        font-size: 1.8rem;
        margin-top: 10px;
    }
     .t-card-person {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: start;
        /* margin: 10pc; */
        margin: 20px 0px;
    }
 .test-data hr{
    width: 80%;
 }
 .test-data h1{
    font-weight: 400;
    font-size: 2.2rem;
 }
 .t-image{
    width: 100%;
 }
    
}