.GIT-container{
    width: 50vw;
    margin: 40px auto;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    padding: 20px;
    justify-content: center;
    align-items: center;
}
.git{
    position:relative;
}
@keyframes mymove {
    0%{
        transform: rotate(0deg);
       }
    100%{
        transform: rotate(360deg);
       }
    }

.GIT-col-1{
    width: 90%;
    height: 100%;
    /* background:url('../Assets/pexels-marek-piwnicki-12966912.jpg'); */
}
.GIT-col-1 > hr{
    width: 70%;
    margin:-20px 0px;
    border: 2px solid #cc0000; 
    margin-left: 30%;  
}

.GIT-col-1 > h1{
    font-size: 3rem;
    font-weight: 600;
}


.GIT-col-2{
}

.GIT-col-2 > p{
    font-size: 1.1rem;
    margin: 45px 0px;
    width: 100%;
}



.git-btn{
    padding: 15px 30px;
    font-weight: 300;
    font-size: 1.5rem;
    color: white;
    background-color: var(--p-red);
    border: 2px solid var(--p-red);
    margin: 20px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content:space-between;
}
.git-btn:hover{
    padding: 15px 30px;
    font-weight: 300;
    font-size: 1.5rem;
    color: var(--p-red);
    background-color:white;
    border: 2px solid var(--p-red);
}


@media all and (width <=430px){

    .GIT-container{
        width: 90%;
        margin: 40px auto;
        display: block;
        gap: 10px;
        padding: 0px;
    }
    .git-btn{
       width: 100%;
    }
}