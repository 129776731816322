.title-overlay {
  font-size: 15rem;
  font-weight: 600;
  opacity: 0.7;
  margin: 0px;
  color: var(--p-red);
}

.Industries-container {
  width: 40%;
  padding: 50px 150px;
  background-color: rgb(244, 244, 244);
  position: relative;
  height: 80vh;
  margin-bottom: 10px;
  border: 1px solid rgb(215, 215, 215);

  border-radius: 5px;
}
.Industries-container-right {
  width: 40%;
  padding: 50px 150px;
  background-color: rgb(244, 244, 244);
  position: relative;
  height: 80vh;
  margin-bottom: 10px;
  border: 1px solid rgb(215, 215, 215);
  margin-left: auto;
  margin-right: 0;
  border-radius: 5px;
}
.Industries-image {
  position: absolute;
  width: 70%;
  height: 70vh;
  left: 90%;
  bottom: 10%;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 5px;
  object-fit: cover;
}
.Industries-image-right {
  position: absolute;
  width: 70%;
  height: 70vh;
  right: 95%;
  bottom: 10%;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 5px;
  object-fit: cover;
}

.Industries-data {
  width: 90%;
  line-height: 190%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  float: right;
}

.Industries-data h4 {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--p-red);
}

.Industries-data h2 {
  font-size: 3rem;
  font-weight: 500;
}

.Industries-data p {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(73, 73, 73);
}

.overlay-number {
  position: absolute;
  top: 0;
  left: -10px;
  font-size: 15rem;
  margin: -50px;
  opacity: 0.05;
}
.overlay-number-right {
  position: absolute;
  top: 0;
  right: -10px;
  font-size: 15rem;
  margin: -50px;
  opacity: 0.05;
}

@media all and (width <= 430px) {
  .Industries-data h2 {
    font-size: 2rem;
    font-weight: 500;
  }
  .Industries-data {
    width: 95%;
  }
  .title-overlay {
    font-size: 4.5rem;
    font-weight: 600;
    opacity: 0.7;
    margin: 0px;
    color: var(--p-red);
  }

  .overlay-number {
    position: relative;
    display: none !important;
    top: 0;
    left: -10px;
    display: block;
    font-size: 15rem;
    margin: -50px;
    opacity: 0.05;
  }
  .overlay-number-right {
    position: relative;
    display: none !important;
    top: 0;
    left: -10px;
    display: block;
    font-size: 15rem;
    margin: -50px;
    opacity: 0.05;
  }

  .Industries-container {
    width: 100%;
    padding: 0px;
    background-color: rgb(244, 244, 244);
    position: relative;
    height: 100%;
    margin: 0px;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 5px;
  }

  .Industries-container-right {
    width: 100vw;
    padding: 0px;
    background-color: rgb(244, 244, 244);
    position: relative;
    height: 100%;
    margin: 0px;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 5px;
  }

  .Industries-image {
    position: static;
    width: 100%;
    height: 50vh;
    left: 90%;
    bottom: 10%;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 5px;
    object-fit: cover;
  }
  .Industries-image-right {
    position: static;
    width: 100%;
    height: 50vh;
    left: 90%;
    bottom: 10%;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 5px;
    object-fit: cover;
  }
  .Industries-image1 {
    position: relative;
    width: 100%;
    height: 50vh;
    left: 0;
    bottom: 10%;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 5px;
    object-fit: cover;
  }
  .Industries-image-right1 {
    position: relative;
    width: 100%;
    height: 50vh;
    left: 0;
    bottom: 10%;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 5px;
    object-fit: cover;
  }
  .Industries-data {
    width: 90%;
    line-height: 190%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    float: none;
    padding: 0px 10px;
  }
}
