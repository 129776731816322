.App{
  margin: 0px auto;
  padding: 0px;
}

.IC-colorgrid{
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.IC-colorgrid  div{
  width: 100%;
  height: 15px;
}

.c-blue{
  background-color:rgb(241, 46, 43);
}

.c-red{
  background-color: rgb(205, 55, 21);
}


.c-green{
  background-color:rgb(208, 85, 23) ;
}

.c-yello{
  background-color: rgb(215, 118, 21) ;
}.c-purple{
  background-color:  rgb(210, 159, 5);
}

.common-btn{
  padding: 15px 30px;
  font-weight: 300;
  font-size: 1.5rem;
  color: white;
  background-color: var(--p-red);
  border: 2px solid var(--p-red);
  margin: 20px 0px;
  cursor: pointer;
  text-decoration: none;
  margin: 30px 0px ;
}
.common-btn:hover{
  padding: 15px 30px;
  font-weight: 300;
  font-size: 1.5rem;
  color: var(--p-red);
  background-color:white;
  border: 2px solid var(--p-red);
}

.TP-container{
  background-color: rgb(236, 236, 236);
  padding: 20px;
  margin: 50px 0px ;
  border: 1px solid rgb(186, 186, 186);
  border-radius: 5px;
}
.TP-container h1{
  font-size: 2.5rem;
  font-weight: 400;
  color: black;
  margin-bottom: 20px;
}
.TP-container p{
  font-size: .8rem;
  font-weight: 400;
  color: rgb(27, 27, 27);
}

.TP-container h2{
  font-size: 1.3rem;
  color: rgb(22, 22, 22);
  margin-bottom: 15px;
  font-weight: 500;
}

.TP-container ul {
  font-size: .8rem;
  color: rgb(22, 22, 22);
  margin-bottom: 10px;
  font-weight: 400;
  list-style: disc;
  
}
.TP-container h3{
  font-size: .9rem;
  font-weight: 400;
  color: black;
}

@media all and (width <=430px){
  .common-btn{
    width: 100% ;
  }
}