.mission-top{
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    align-items: start;

}
.mission-top-col-1  hr{
    border: 2px solid var(--p-red);
    width: 60%;
    align-self: left;
}

.mission-top-col-1 > h1{
    font-size: 3.2rem;
    margin-bottom: -6px;
    font-weight: 500;

}
.mission-bottom{
    display: grid;
    grid-template-columns: 32% 32% 32%;
    margin: 30px 0px;
    gap: 20px;
}

.image-card{
    object-fit: cover;

}
.image-image{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: .5px solid rgb(227, 227, 227);
}


/* Why Choose us  */

.WCU-image-card{
width: 100%;
height: 100%;
position: relative;
border-radius: 5px;
object-fit: cover;
}

.WCU-image-image{
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 5px;
}

.WCU-overlay:hover{
    position: absolute;
    top:0;
    padding: 30px;
    background-color: white;
    margin: 20px;
    height: 60%;
    opacity: .9;
}

.WCU-overlay{
    position: absolute;
    top:0;
    padding: 30px;
    background-color: white;
    margin: 20px;
    height: 60%;
    opacity: 0;
}