.chatbox{
    position: fixed;
    bottom: 5%;
    right: 0%;
    background-color:var(--p-red);
    padding: 20px;
    color: white;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    z-index: 4;
    cursor: pointer;
}
.backtotop{
    position: fixed;
    bottom: 5%;
    left: 0%;
    color: white;
    padding: 20px;
    background-color: var(--p-red);
    border-top-right-radius: 50%;
    border-bottom-right-radius:50%;
    cursor: pointer;
    z-index: 11;
}
.chatbox-container{
    position: fixed;
    bottom: 10%;
    right: 5%;
    background-color: rgb(246, 246, 246);
    border: 1px solid rgb(209, 209, 209);
    width: 22vw;
    z-index:11;
    height: 80vh;
    border-radius: 10px;
    overflow-y: scroll;
    box-shadow: -6px -3px 3px -2px #0d0d0d36;
}
.chatbox-container span{
    color: red;
    font-weight: 300;
    font-size: .7rem;
    margin: 0;
}
.upper-box{
    box-shadow: 0px 1px 4px 0px #00000052;
    position: sticky;
    width: 97%;
    top: 0;
    padding: 5px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
    border-bottom: 1px solid rgb(216, 216, 216);
}
.chatbox-container p {
    padding: 20px 10px;
    background-color: white;
    width: 70%;
    font-size: .8rem;
    color: var(--p-red);
    border: 1px solid rgb(220, 220, 220);
}
.bot{
    margin-left: 10px;
    border-radius: 10px 10px 10px 0px ;
}
.upper-box h1{
    font-size: 1.2rem;
    font-weight: 400;
}
.down-box{
    padding: 10px 10px;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
}
.chatbox-container-service-list {
    display: flex;
    list-style: none;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
    padding: 5px !important;
    font-size: .8rem;
    font-weight: 400;
    color: white;
}
.chatbox-container-service-list li {
    padding: 5px 10px ;
    background-color: var(--p-red);
    border-radius: 20px;
    cursor: pointer;
}
.user{
    float: right;
    margin-right: 10px;
    border-radius: 10px 10px 0px 10px ;
}
    .form{
        width: 70%;
        float: right;
        padding: 10px;
        border-radius: 10px 10px 0px 10px;
       margin-right: 10px;
       background-color: white;
    }

    .chatbox-input-box{
        width: 90%;padding: 15px 10px;
        font-size: 1rem;
        outline: none;
        border-radius: 5px;
        margin: 10px 0px ;
        border: 1px solid rgb(171, 171, 171);
    }
    
    .chatbox-button {
        width: 100%;
        padding: 15px 10px;
        background-color: var(--p-red);
        color: white;
        cursor: pointer;
        border: none;
        border-radius: 10px;
        font-weight: 500;
    }

    @media all and (width <= 430px) {
        .chatbox-container{
            width: 88vw;
        }
        .chatbox{
            z-index: 12 ;
        }
        
    }

/* .upper-box{
    width: 100%;
    height: 100%;
    background-color: blue;
}
.chatbox-container{
    position: fixed;
    height: 70vh;
    width: 20vw;
    bottom: 10%;
    right: 5%;
    background-color: rgb(224, 224, 224);
  border-radius: 6px;
    color: var(--p-red);
    border: 1px solid rgb(210, 210, 210);
    box-shadow: -3px -2px 3px 0px #d4d4d4a6;;
    z-index: 10; 
    overflow-y: scroll;
}  
.chatbox-servicelist{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
}

.chatbox-servicelist li {
    padding: 4px 8px;
    border: 1px solid var(--p-red);
    color: white;
    font-weight: 500;
    background-color: var(--p-red);
    border-radius: 10px;
    cursor: pointer;
}
.chatbox-servicelist li:hover {
    color: var(--p-red);
background-color: white;
}
.chatbox-input-box{
    width: 90%;padding: 15px 10px;
    font-size: 1rem;
    outline: none;
    border-radius: 5px;
    margin: 10px 0px ;
    border: 1px solid rgb(171, 171, 171);
}

.chatbox-button {
    width: 100%;
    padding: 15px 10px;
    background-color: var(--p-red);
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 500;
}
.chatbox-button:hover{
    background-color: white;
    color: var(--p-red);
}

.chatbox-container h3{
    font-weight: 400;
    font-size: 1.5rem;
    color: white;
    background-color: var(--p-red);
    width: 70%;
    padding: 13px;
    border-radius: 10px 10px 10px 0px;
}
.chatbox-container h4{
    font-weight: 400;
    font-size: .8rem;
    width: 70%;
    padding: 13px;
    border-radius: 10px 10px 10px 0px;
    color: white;
    background-color: var(--p-red);
}


.thankyou-section{
    width: 70%;
    border: 1px solid var(--p-red);
    float: right;
    padding: 5px;
    border-radius: 10px 10px 0px 10px;
}

@media all and (width<=430px) {
    .chatbox-container{
        width: 75%;
        height: 80vh;
    }
    .chatbox{
        z-index:11;
    }
    .backtotop{
        bottom: 11%;
        padding: 10px;
        
    }
    .chatbox{
        bottom: 11%;
        padding: 10px;
    }
} */


/* new CSS */

/* .chatbox-outer-container{
    position: absolute;
    bottom: 10;
    right: 10;
    back
} */