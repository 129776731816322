/* .SCard-data{
  position: absolute;
  bottom:0;
  padding: 10px 5px;
  border-top-right-radius: 20px;
  background-color: rgba(255, 255, 255, 0.889);
} */
.SCard-data h1{
    font-size: 1.8rem;
    font-weight: 400;
}
.SCard-data  a{
    font-size: 1rem;
    color: var(--p-red);
    font-weight: 500;
    text-decoration: none;
}
.service-container{
    padding: 50px 0px;
}
.SCard-container h4{
    font-size: .9rem;
    font-weight: 600;
    margin-bottom: 0px;
    color: rgb(103, 103, 103);
}

.service-heading{
    font-size: 3rem;
    font-weight: 400;
    margin: 0px;
    
}

.service-hr{
    border: 3px solid var(--p-red);
    width: 20%;
    margin-left: 6%;
    margin-top: 0px;
}
.slider-services .nextArrow {

    top: 105%;
    position: absolute;
    left: 8%;
    right: 55%;
    z-index: 1;
    height: 30px;
    width: 30px;
    border: 1px  solid var(--p-red);
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    color: white !important;
    background-color: var(--p-red);
  }
  
  .slider-services .prevArrow {

    top: 105%;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border: 1px  solid var(--p-red);
    padding: 10px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    left: 0%;
    right: 45%;
    color: white !important;
    background-color: var(--p-red);

  }
  
  .slider-services .prevArrow:hover{
    color: var(--p-red) !important;
    background-color: white;
  }
 
  .slider-services .nextArrow:hover{
    color: var(--p-red) !important;
    background-color: white;
  }

  @media all and (width<=430px) {
  
  .slider-services .nextArrow {
      left: 15%;
      height: 15px;
      width: 15px;
  }
  .slider-services .prevArrow{
    height: 15px;
      width: 15px;
  }
    .service-container{
      margin-bottom: 50px ;
      height: auto;
    }


  }