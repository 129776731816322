.MQ-container{
    margin: 100px 0px ;
    padding: 40px 80px;
    position: relative;
    /* width: 100vw; */
    height: 40vh;
    background: url('../../Assets/pexels-marek-piwnicki-12966912.jpg');
    background-repeat: no-repeat;
    /* overflow-y: hidden; */

}

.MQ-col1{
    position: absolute;
    top: -40px;
    left: 100px;
    width: 40vW;
    /* height: 40vh; */
    background-color:#262626;
    padding: 20px 30px;
    color: white;
    opacity: .99;
    /* box-shadow: -3px -6px 6px 0px #cbcbcb; */
}
.MQ-col1  h4{
    font-size: 1rem;
    font-weight: 400;
    margin-left: 20px;
}
.MQ-col1  h2{
    font-size: 2.4rem;
    font-weight: 400;
    margin-left: 20px;
}
.MQ-col1  p{
    font-size: 1rem;
    font-weight: 300;
    margin-left: 20px;
    line-height: 190%;
}
.MQ-col2{
    position: absolute;
    bottom: -40px;
    width: 40vW;
    right: 40px;
    background-color:#262626;
    padding: 20px 30px;
    color: white;
    opacity: .99;
    /* box-shadow: -3px -6px 6px 0px #242424; */
}
.MQ-col2 h4{
    font-size: 1rem;
    font-weight: 400;
    margin-left: 20px;
}
.MQ-col2  h2{
    font-size: 2.4rem;
    font-weight: 400;
    margin-left: 20px;
}
.MQ-col2  p{
    font-size: 1rem;
    font-weight: 300;
    margin-left: 20px;
    line-height: 190%;
}



/* media query */
@media all and (width <= 430px){
    .MQ-container {
        margin: 0px 0px;
        padding: 20px;
        height: auto;
    }
    .MQ-col1 h2{
        font-size: 1.6rem;
    }
    .MQ-col2 h2{
        font-size: 1.6rem;
    }
    .MQ-col2 {
        position: static;
        bottom: -40px;
        width: 76vW;
        right: 40px;
        background-color: #262626;
        padding: 20px 30px;
        color: white;
        opacity: .99;
        margin-bottom: 20px;
    }
    .MQ-col1 {
        position: static;
        bottom: -40px;
        width: 76vW;
        right: 40px;
        background-color: #262626;
        padding: 20px 30px;
        color: white;
        opacity: .99;
        margin-bottom: 20px;
    }
}