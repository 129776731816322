.about-container{
    padding: 50px 0px;
    gap: 20px;
}

.about-col-1 {
    align-self: center;
}

.about-image{
    width: 90%;
    height: 100%;
    /* padding-right: 20px; */
    object-fit: cover;
    border-top: 5px solid var(--p-red);
} 

.about-col-2 {
    /* background-color: rgb(215, 215, 215); */
}

.about-col-2 h6{
    font-size: .8rem;
    font-weight: 600;
    margin-bottom: -20px;
letter-spacing: .11rem;
}

.about-col-2 a {
    padding: 15px 25px;
    border: 2px solid var(--p-red);
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
    margin: 30px 0px;
    background-color: var(--p-red);
}
.about-col-2 a:hover {
    color: var(--p-red);
background-color:white;
}
/* .about-col-2 button{
    padding: 15px 30px;
    font-weight: 300;
    font-size: 1.5rem;
    color: var(--p-red);
    background-color: white;
    border: 2px solid var(--p-red);
    margin: 20px 0px;
    cursor: pointer;
} */
.input-box input{
    border: 1px solid rgb(190, 190, 190);
    padding: 10px 20px ;
    width: 90%;
    font-size: 1.2rem;
    margin: 10px 0px ;
    border-radius: 5px ;
    outline: none;

}
.input-box{
    display: flex;
    justify-content: space-between;

}
/* .about-col-2 button:hover{
    padding: 15px 30px;
    font-weight: 300;
    font-size: 1.5rem;
    color: white;
    background-color:var(--p-red);
    border: 2px solid var(--p-red);
} */

.down-line{
    border:2px solid var(--p-red);
    margin-left: 10%;
    width: 50%;
    margin-top: 0px ;
}

.about-col-2 h2{
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 0px;
}
.about-col-2 p {
    /* margin: 10px 0px; */
    font-size: 1rem;
    font-weight: 400;
    color: rgb(73, 73, 73);
    line-height: 190%;
}


@media all and (width<=430px){
    .about-image{
        width: 100%;
        height: 100%;
    }
    .about-col-2 a {
        width: auto;
        display: block;
    }
}