
.herocard-container {
  width: 60%;
  height: 80vh;
  margin: -50px 0px;
  object-fit: cover;
}
.HS-section button {
  padding: 10px 20px;
  border: 1px solid var(--p-red);
  display: block;
  margin: 20px auto;
  font-size: 1.8rem;
  font-weight: 500;
  background-color: white;
}

.herocard-container img {
  width: 100%;
  height: 100%;
  position: relative;
}
.herocard-data {
  width: 50vw;
  height: 40vh;
  background-color: var(--p-red);
  padding: 40px;
  position: absolute;
  z-index: 2;
  bottom: 60px;
  right: 0px;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 0px -10px 5px rgba(26, 26, 26, 0.6);
}
.herocard-data h1 {
  font-weight: 500;
  font-size: 2.8rem;
}
.herocard-data button {
  padding: 20px 40px;
  border: 1px solid black;
  background-color: #fff;
  cursor: pointer;
}
.HS-h1 {
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: -50px;
}

.HS-h2 {
  font-size: 5rem;
  font-weight: 700;
  margin-top: -30px;
  /* position: absolute; */
  right: 0px;
  text-align: right;
  margin-bottom: 20px;
}
.slider-outer-div .nextArrow {
  color: white !important;
  top: 84.5%;
  position: absolute;
  left: 11.5%;
  /* right: 45%; */
  z-index: 1;
  background-color: rgba(255,255,255,.2);
  height: 30px;
  width: 30px;
  padding: 10px;
  cursor: pointer;
  border-radius: 20%;
}

.slider-outer-div .prevArrow {
  color: rgb(255, 255, 255) !important;
  /* left: 50% !important;
    right: 50% !important; */
  top: 84.5%;
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 1;
  left: 7.5%  ;
  /* right: 55%; */
  padding: 10px;
  background-color: #ffffff;
  background-color: rgba(255,255,255,.2);
  cursor: pointer;
  border-radius: 20%;
}

.slider-outer-div .prevArrow:hover{
  background-color: rgba(255,255,255,.3 );
} 
.slider-outer-div .nextArrow:hover{
  background-color: rgba(255,255,255,.3 );
} 

@media all and (width<=430px) {
  .herocard-container {
    
    height: auto;
    
  }
  .slider-outer-div .nextArrow {
    right: 10%;
    background-color:rgba(255,255,255,.3);
    color: white;
    left: 80%;
}
.slider-outer-div .prevArrow {
  /* right: 92%; */
  background-color:rgba(255,255,255,.3);
  color: white;
  left: 3%;
}
  
}