.header-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
  border-top: 5px solid var(--p-red);
}

.header-logo-menu {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-links {
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
}

.lili {
  color: black;
  text-decoration: none;
}

.header-links li {
  text-decoration: none;
  color: gray;
  font-weight: 500;
}
.header-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.active {
  color: var(--p-red);
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 3px;
}

.sidebar-menu-open {
  position: fixed;
  bottom: -28px;
  width: 400px;
  height: 90vh;
  z-index: 9;
  background-color: white;
}

.sidebar-menu-close {
  display: none;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  gap: 30px;
  list-style: none;
  padding: 40px 50px;
  font-size: 2rem;
}

.sidebar-links li {
  font-size: 1rem;
}


@media  all and (width <=430px){
    .header-container {
        padding: 20px ;
      }
      .header-logo-menu {
        justify-content: space-between;
        width: 100vw;
    }
      
      .header-links {
        display: none;
      }
      .sidebar-menu-open {
        position: fixed;
        top: 10%;
        width: 100%;
        height: 93vh;
        overflow-y: none;
      }
      .sidebar-links {
        padding: 40px 40px;
      }
      .sidebar-links li {
        font-size: 1rem;
      }
}

