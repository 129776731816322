.hero-card-container{
    height: 90vh;
}

.hero-card-col-1{
    background-color: #A32020;
    color: white;
    padding: 80px 120px;
}

.hero-card-col-1 h6 {
    font-size: .8rem;
    font-weight: 500;
    letter-spacing: 2px;
}

.hero-card-col-1 h1 {
    font-size: 4.2rem;
    font-weight: 400;
    letter-spacing: 1px;
}
.hero-card-col-1 p {
    font-size:1rem;
    font-weight: 300;
    letter-spacing: 2px;
    width: 100%;
}

.hero-card-col-1 button{
    padding: 15px 25px;
    border :2px solid white;
    background-color: transparent;
    font-size: 1.3rem;
    color: white;
    margin: 20px 0px;
    cursor: pointer;
}

.hero-card-col-1 button:hover{
    background-color: white;
    color:  #A32020;
    
}

.hero-card-col-2{
    object-position: bottom;
}
.hero-card-col-2 img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: bottom;
}


@media all and (width <= 430px){

    /* .hero-card-container{
        height: auto;
    }
    .hero-card-col-1 {
        background-color:transparent;
        color: rgb(255, 0, 0);
        padding: 10px;
        position: absolute;
        height:100vh;
    } */
    /* .hero-card-col-1 h1{
        font-size: 3.2rem;
        font-weight: 500;
        color: var(--p-red);
    }
    .hero-card-col-1 p{
        font-size: .9rem;
        padding: 3px;
        font-weight: 400;
        letter-spacing: 2px;
        width: 100%;
        color: var(--p-red);
        background-color: #ffffff82;
    }
    .hero-card-col-1 button {
        width: 100%;
        background-color: var(--p-red);
        color: white;
        border: 2px solid var(--p-red);
    }
    .hero-card-col-1 button:hover {
        width: 100%;
        background-color: transparent;
        color: white;
        border: 2px solid var(--p-red);
    } */

    .hero-card-col-1{
        padding: 10px;
        height: auto;;
    }
    .hero-card-col-2{
        display: none;
    }
    .hero-card-col-1 h1{
        font-size: 3rem;
    }
    
}