.career-option-container {
  height: 30vh;
  padding: 30px 80px;
  background: url('../../Assets/pexels-marek-piwnicki-12966912.jpg');
  color: white;
  position: relative;
  margin: 100px 0px;
}

.career-option-container h1 {
  font-size: 3rem;
  font-weight: 500;
}

.career-option-data {
  position: absolute;
  background-color: black;
  padding: 40px;
  right: 250px;
  bottom: -20%;
  width: 20vw;
}

.career-option-data h3 {
  font-size: 2rem;
  font-weight: 400;
}

.career-option-data h4 {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  padding: 2px 3px;
  border-radius: 2px;
}
.career-option-data h4:hover {
  color: white;
  background-color: var(--p-red);
}

.join-slider .nextArrow {
  color: white !important;
  top: 54.5%;
  position: absolute;
  left: 9% ;
  right: 55%;
  z-index: 1;
  background-color: black;
  border-radius: 50%;
  padding: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border: 1px solid black;
}

.join-slider .prevArrow {
  color: white !important;

  top: 54.5%;
  border-radius: 50%;
  background-color: black;
  padding: 10px;
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1;
  left: 5%;
  right: 45%;
  border: 1px solid black;
  cursor: pointer;
}

.join-slider .prevArrow:hover , .nextArrow:hover{
    background-color: rgba(255, 255, 255, 0.3);
    color: black !important;
}
.join-slider  .nextArrow:hover{
  background-color: rgba(255, 255, 255, 0.3);
  color: black !important;
}

@media all and (width<=430px) {
  .career-option-container{
    height: 70vh;
    padding: 20px;
    text-align:-webkit-center;
    margin: 20px 0px ;
  }
  .career-option-container h1{
    font-size: 1.5rem;
    text-align: left;
  }
  .join-slider .nextArrow {
    color: white !important;
    top: 25%;
    left: 20%;
    right: 55%;
  }
  .join-slider .prevArrow {
    color: white !important;
    top: 25%;
    border-radius: 50%;
    background-color: black;
    padding: 10px;
    height: 20px;
    width: 20px;
    position: absolute;
    z-index: 1;
    left: 5%;
    right: 45%;
    border: 1px solid black;
    cursor: pointer;
}
.career-option-data {
  background-color: black;
  padding: 40px;
  right: 0;
  width: 300px;
  margin-top: 15vh;
  position: static;
  top: 35%;
  text-align: justify;
}
}