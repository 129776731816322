:root{
  --p-red:#cc0000;
  --p-pink:#E30061;
  --p-purple:#C72AB6;
  --t-purpleblue:#580be8;
  --t-blue:#656AF4;
  --t-seablue:#656AF4;
}

body {
  margin: 0;
  font-family: poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media all and (width<=430px){
  body{
    overflow-x: hidden;
  }
  html{
    overflow-x: hidden;
  }
}
body{
  overflow-x:hidden;
}

::-webkit-scrollbar{display: none;}

.common-wrapper{
  padding: 20px 150px ;
}

@media all and (width <=430px) {
  .common-wrapper{
    padding: 20px;
  }
  
}
h2{
  font-size: 2rem;
  font-weight: 500;
  
}