.F-container{
    z-index: 9;
    width: auto;
    height: auto;
    background-color: rgb(226, 226, 226);
}

.f-follow{
display: flex;
gap:50px;
align-items: center;
justify-content: start;
margin: 10px 0px;
}
.f-follow > h1{
    font-size: 1.5rem;
    font-weight: 400;
}

.f-follow-icons{
display: flex;
gap:10px;
font-size: 30px;
align-items: center;
}
.f-follow-icons  a{
    color: rgb(41, 41, 41);
}
.f-links{
display: flex;
align-items: center;
gap: 30px;
}
.f-links  h6{
    font-weight: 300;
}

.icon-hover:hover{
    color: var(--p-red);
}

.f-list{
display: flex;
gap:20px;
list-style-type: none;
font-size: .8rem;
}
.f-list a {
    text-decoration: none;
    color: black;
    
}
.f-list a:hover {
    text-decoration: none;
    color: var(--p-red);
    
}

@media all and (width <=430px){
.f-follow {
    display: flex;
    gap: 0PX;
    align-items: START;
    justify-content: start;
    margin: 10px 0px;
    flex-direction: column;
}

.f-links {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-direction: column-reverse;
}

.f-list {
    display: flex;
    gap: 20px;
    list-style-type: none;
    font-size: .8rem;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100;
    width: 100%;
    padding-left: 0px;
}

.barrs{
    display: none;
}
}