.IC-container {
  width: 100%;
  height: 70vh;
  background-color: transparent;
  position: relative;
}

.overlays {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.IC-container > img {
  width: 100%;
  position: absolute;
  height: 100%;
  object-fit: cover;
  background-attachment: fixed;
}
.IC-container > h1 {
  position: absolute;
  color: rgb(255, 255, 255);
  z-index: 1;
  bottom: -60px;
  left: 30px;
  font-size: 8rem;
  font-weight: 500;
}


@media all and (width <=430px){
    .IC-container {
       
        width: 100%;
        height: 45vh;
      }
      .IC-container > h1 {        
        bottom: 0px;
        font-size: 2.7rem;

      }
      
}