
.Industry-Grid{
    padding: 30px 80px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 20px; 
}

.TOICard-container{
    width: 100%;
    height: 100%;
}
.TOICard-container img {
    width: 100%;
    position: relative;
    border-radius: 5px;
    border-top: 5px solid var(--p-red);
}

.TOICard-data{
    width: 96%;
    padding: 2px 10px ;
    background-color: rgb(246, 246, 246);
    bottom: 0;
}

.TOICard-data h4{
    font-size: 1.5rem;
    font-weight: 500;
    margin: 2px 0px;
    color: var(--p-red);
    /* text-decoration: underline;
    text-underline-offset: 5px; */
}

@media all and (width <=430px){
    .Industry-Grid{
        display: block;
        padding: 20px;
    }
    .TOICard-container {
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
    }
}