.contact-middle{
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: space-between;
    align-items: center;
    margin: 30px auto;

    
}

.card{
    border: 1px solid rgb(185, 185, 185);
    border-radius: 5px;
    padding:15px ;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 3px solid var(--p-red);
}

.card > h1{
    font-weight: 500;
    font-size: 1.5rem;
}


.cardlink>button{
    width: 100%;
    text-decoration: none;
    padding: 10px 25px;
    font-weight: 400;
    font-size: 1.2rem;
    background-color: var(--p-red);
    border: 1px solid var(--p-red);
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
.cardlink>button:hover{
    background-color: white;
    color: var(--p-red);
}

@media all and (width<=430px) {
    .contact-middle{
        padding: 20px;
        display: block;
        
    }
    .card{
        margin-bottom: 20px;
    }
    
}