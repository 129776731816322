.career-grid{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: center;
    align-items: center;
    gap: 0px;
}

.career-container h6{
    font-size: .9rem;
    font-weight: 500;
    margin-bottom: 0px;
    color: rgb(59, 59, 59);
}

.career-image{
    width: 95%;
    position: relative;
}

.career-data{
    padding: 10px 20px;
    background-color: rgb(243, 243, 243);
    width: 86%;
    margin: 0px;
}

.career-data h1{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0px;

}
.career-data  p {
    font-size: 1rem;
    font-weight: 300;
    margin: 0px;
}
.jt-heading{
    font-size: 2.2rem;
    font-weight: 400;
    margin: -5px 0px;
} 
.jt-hr{
    width: 20%;
    border: 2px solid var(--p-red);
    margin: 0px 0px ;
    margin-left: 5%;
}
@media all and (width<=430px) {
 .career-grid{
    display: block;
 
}
.jt-heading{
    font-size: 2.2rem;
    font-weight: 400;
    margin: 0px 0px;
} 
.jt-hr{
    width: 80%;
    border: 2px solid var(--p-red);
    margin: 0px 0px ;
    margin-left: 10%;
}
}